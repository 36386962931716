import { reqGetImageList, reqRemoveImage, reqGetFrameList, reqRemoveFrame } from '@/services/linein';
import { Base64 } from 'js-base64';

const Model = {
  namespace: 'lineinGallery',
  state: {
    imageList: null,
    frameList: null,
  },
  effects: {
    *getImageList({ payload }, { call, put }) {
      // console.log('model getImageList', payload)
      const response = yield call(reqGetImageList, {activity: payload.activity});
      yield put({
        type: 'reduceImageList',
        payload: response,
      });
      // message.success('婚紗照讀取成功');
    },
    *removeImage({ payload }, { call, put }) {
      // console.log('model removeImage', payload)
      const response = yield call(reqRemoveImage, payload);
      // message.success('婚紗照讀取成功');
    },
    *getFrameList({ payload }, { call, put }) {
      // console.log('model getImageList', payload)
      const response = yield call(reqGetFrameList, {activity: payload.activity});
      yield put({
        type: 'reduceFrameList',
        payload: response,
      });
      // message.success('婚紗照讀取成功');
    },
    *removeFrame({ payload }, { call, put }) {
      // console.log('model removeImage', payload)
      const response = yield call(reqRemoveFrame, {...payload, key: Base64.encode(payload.key)});
      // message.success('婚紗照讀取成功');
    },
  },
  reducers: {
    reduceImageList(state, action) {
      return { ...state, imageList: action.payload || null};
    },
    reduceFrameList(state, action) {
      return { ...state, frameList: action.payload || null};
    },
  },
};
export default Model;
