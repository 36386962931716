import request from '@/utils/request';

export async function reqGetMaterialList(params) {
  // console.log('getFileListForm params', params)
  return request('/material/list/' + params.activity, {
    method: 'GET',
    data: params,
  });
}

export async function reqRemoveMaterial(params) {
  // console.log('getFileListForm params', params)
  return request('/material/remove/' + params.key, {
    method: 'GET',
    // data: params,
  });
}

export async function reqGetImageList(params) {
  // console.log('getFileListForm params', params)
  return request('/image/list/' + params.activity, {
    method: 'GET',
    data: params,
  });
}

export async function reqRemoveImage(params) {
  // console.log('getFileListForm params', params)
  return request('/image/remove/' + params.key, {
    method: 'GET',
    // data: params,
  });
}

export async function reqGetFrameList(params) {
  // console.log('getFileListForm params', params)
  return request(`/activity/${params.activity}/frame/list/`, {
    method: 'GET',
    data: params,
  });
}

export async function reqRemoveFrame(params) {
  // console.log('getFileListForm params', params)
  return request(`/activity/${params.activity}/frame/remove/${params.key}`, {
    method: 'GET',
    // data: params,
  });
}
