import request from '@/utils/request';

export async function postAccountLogin(params) {
  return request('/api/login/account', {
    method: 'POST',
    data: params,
    // prefix: 'http://mtk.today:7001',
  	credentials: 'include', // 默認請求是否帶上cookie
  });
}
export async function getLogout() {
  return request('/api/logout', {
    method: 'GET',
    // prefix: 'http://mtk.today:7001',
  	credentials: 'include', // 默認請求是否帶上cookie
  });
}