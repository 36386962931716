import { routerRedux } from 'dva/router';
import { stringify } from 'querystring';
import { postAccountLogin, getLogout } from '@/services/login';
import { setAuthority } from '@/utils/authority';
import { getPageQuery } from '@/utils/utils';
import { actionChannel } from 'redux-saga/effects';

import { reqFetchAdmins, reqFetchAdminInit } from '@/services/admins';
import _ from 'lodash';
import { buffers } from 'redux-saga';
import React, { useState, forwardRef, useRef, useMemo } from "react";
import { delay } from 'redux-saga';
import { Select } from "antd";
const { Option, OptGroup } = Select;

const Model = {
  namespace: 'admins',
  state: {
    adminList: [],
    adminGroup: {},
    adminOptions: [],
  },
  effects: {
    *fetch_admins({ payload }, { call, put }) {
      console.log('reqFetchAdmins', payload)
      // yield put({ type: 'reset'});
      const response = yield call(reqFetchAdmins, payload);
      yield put({
        type: 'updateAdmins',
        payload: response.admins,
      });
      // message.success('讀取成功');
    },

    service: [
      function *({take, call, flush, select, put}){
        const ch = yield actionChannel('admin_fetch', buffers.sliding(1000));

        yield put({
          type: 'fetch_admins',
          payload: { type:'user_selectable'},  //todo: fetch correct admins
        });

        while(true){
          const actions = [yield take(ch), yield call(delay, 1000), ...yield flush(ch)];
          // const info = yield select(state=>state.activityList.admins);
          // console.log('admin_fetch', info);
          const filtered = _.uniq(actions.map(({payload})=>payload).filter(id=>id));
          console.log('admin_fetch filteded', filtered);
          if(filtered.length==0){ continue; }
          const response = yield call(reqFetchAdmins, {"ids":filtered});
          if(response.success){
            yield put({
              type: 'updateAdmins',
              payload: response.admins,
            })
          }
        }
      },
      { type: 'watcher' },
    ],
  },
  reducers: {
    updateAdmins(state, {payload}){
      const newAdmins = _.keyBy(payload, 'account');
      // console.log('updateAdmins', newAdmins, payload);

      const newGroup = {...state.adminGroup};

      payload?.forEach(admin=>{
        if(!newGroup.hasOwnProperty(admin.groupName)){
          newGroup[admin.groupName]=[admin];
        }else if(_.find(newGroup[admin.groupName], {account: admin.account})==undefined){
          newGroup[admin.groupName].push(admin);
        }
      })

      const newOptions = Object.keys(newGroup).map(groupName=>
        <OptGroup label={groupName} key={groupName}>
          {newGroup[groupName].map(adm=><Option key={adm.account} value={adm.account}>{adm.name}</Option>)}
        </OptGroup>
      );

      return {
        ...state,
        adminList: {...state.adminList, ...newAdmins},
        adminGroup: newGroup,
        adminOptions: newOptions,
      };
    },

  },
};
export default Model;
