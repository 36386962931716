export default {
  'activity-add.basic.title': '基礎表單',
  'activity-add.basic.description': '新增活動場次',

  'activity-add.goal.placeholder': '拍拍印 - 最強婚禮拍貼機',
  'activity-add.lottery_num': '抽獎活動數量',
  'activity-add.print_num': '每位賓客列印額度',
  'activity-add.qrcode': '桌卡編號',
  'activity-add.title.label': '標題',
  'activity-add.title.placeholder': 'Line顯示活動名稱',
  'activity-add.title.required': '請輸入標題',

  'activity-add.id.label': 'ID',
  'activity-add.id.placeholder': '190824_judy',
  'activity-add.id.required': '請輸入ID',

  'activity-add.desc.label': '官方帳號描述',
  'activity-add.desc.required': '請輸入官方帳號描述',
  'activity-add.desc.placeholder': '拍拍印 - 最強婚禮拍貼機',

  'activity-add.icon.label': 'Icon網址',

  'activity-add.welcome.label': '官方帳號歡迎詞',
  'activity-add.welcome.placeholder': '歡迎來參加我們的婚禮',
  'activity-add.welcome.required': '請輸入官方帳號歡迎詞',

  'activity-add.date.label': '活動日期',
  'activity-add.date.required': '請輸入活動日期',

  'activity-add.time.label': '活動時間',
  'activity-add.form.submit': '建立',
};