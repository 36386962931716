import React from 'react';
import {
  Router as DefaultRouter,
  Route,
  Switch,
  StaticRouter,
} from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@@/history';
import RendererWrapper0 from '/home/ship/linein-console/src/pages/.umi/LocaleWrapper.jsx';
import _dvaDynamic from 'dva/dynamic';

const Router = require('dva/router').routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/user',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__UserLayout" */ '../../layouts/UserLayout'),
          LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/UserLayout').default,
    routes: [
      {
        name: 'login',
        path: '/user/login',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__user__login" */ '../user/login'),
              LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
                .default,
            })
          : require('../user/login').default,
        exact: true,
      },
      {
        component: () =>
          React.createElement(
            require('/home/ship/linein-console/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
              .default,
            { pagesPath: 'src/pages', hasRoutesInConfig: true },
          ),
      },
    ],
  },
  {
    path: '/c',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__CustomerLayout" */ '../../layouts/CustomerLayout'),
          LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/CustomerLayout').default,
    routes: [
      {
        path: '/report/:key?',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__activity__customer__model.js' */ '/home/ship/linein-console/src/pages/activity/customer/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__activity__customer" */ '../activity/customer'),
              LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
                .default,
            })
          : require('../activity/customer').default,
        exact: true,
      },
      {
        path: '/c/:activity/:pwd/:tab?',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__activity__customer__model.js' */ '/home/ship/linein-console/src/pages/activity/customer/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__activity__customer" */ '../activity/customer'),
              LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
                .default,
            })
          : require('../activity/customer').default,
        exact: true,
      },
      {
        component: () =>
          React.createElement(
            require('/home/ship/linein-console/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
              .default,
            { pagesPath: 'src/pages', hasRoutesInConfig: true },
          ),
      },
    ],
  },
  {
    path: '/q',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__BlankLayout" */ '../../layouts/BlankLayout'),
          LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/BlankLayout').default,
    routes: [
      {
        path: '/q/:account/:quickAuthKey',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__activity__quick__model.js' */ '/home/ship/linein-console/src/pages/activity/quick/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__activity__quick" */ '../activity/quick'),
              LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
                .default,
            })
          : require('../activity/quick').default,
        name: '',
        exact: true,
      },
      {
        component: () =>
          React.createElement(
            require('/home/ship/linein-console/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
              .default,
            { pagesPath: 'src/pages', hasRoutesInConfig: true },
          ),
      },
    ],
  },
  {
    path: '/create_invitation',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__BlankLayout" */ '../../layouts/BlankLayout'),
          LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/BlankLayout').default,
    routes: [
      {
        path: '/create_invitation/:key',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__activity__invite__model.js' */ '/home/ship/linein-console/src/pages/activity/invite/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__activity__invite" */ '../activity/invite'),
              LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
                .default,
            })
          : require('../activity/invite').default,
        name: '',
        exact: true,
      },
      {
        component: () =>
          React.createElement(
            require('/home/ship/linein-console/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
              .default,
            { pagesPath: 'src/pages', hasRoutesInConfig: true },
          ),
      },
    ],
  },
  {
    path: '/invitation_auth',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__BlankLayout" */ '../../layouts/BlankLayout'),
          LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/BlankLayout').default,
    routes: [
      {
        path: '/invitation_auth/:activity/:token',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__activity__invite_auth__model.js' */ '/home/ship/linein-console/src/pages/activity/invite_auth/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__activity__invite_auth" */ '../activity/invite_auth'),
              LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
                .default,
            })
          : require('../activity/invite_auth').default,
        name: '',
        exact: true,
      },
      {
        component: () =>
          React.createElement(
            require('/home/ship/linein-console/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
              .default,
            { pagesPath: 'src/pages', hasRoutesInConfig: true },
          ),
      },
    ],
  },
  {
    path: '/editor',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__CustomerLayout" */ '../../layouts/CustomerLayout'),
          LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/CustomerLayout').default,
    routes: [
      {
        path: '/editor/:id',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__editor__user__model.js' */ '/home/ship/linein-console/src/pages/editor/user/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__editor__user" */ '../editor/user'),
              LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
                .default,
            })
          : require('../editor/user').default,
        name: '邊框設定',
        exact: true,
      },
      {
        component: () =>
          React.createElement(
            require('/home/ship/linein-console/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
              .default,
            { pagesPath: 'src/pages', hasRoutesInConfig: true },
          ),
      },
    ],
  },
  {
    path: '/printing',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__BlankLayout" */ '../../layouts/BlankLayout'),
          LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/BlankLayout').default,
    routes: [
      {
        path: '/printing/:id',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__activity__printing__model.js' */ '/home/ship/linein-console/src/pages/activity/printing/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__activity__printing" */ '../activity/printing'),
              LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
                .default,
            })
          : require('../activity/printing').default,
        name: '列印監控',
        exact: true,
      },
      {
        component: () =>
          React.createElement(
            require('/home/ship/linein-console/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
              .default,
            { pagesPath: 'src/pages', hasRoutesInConfig: true },
          ),
      },
    ],
  },
  {
    path: '/schedule',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__BlankLayout" */ '../../layouts/BlankLayout'),
          LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/BlankLayout').default,
    routes: [
      {
        path: '/schedule/:id',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__activity__schedule__model.js' */ '/home/ship/linein-console/src/pages/activity/schedule/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__activity__schedule" */ '../activity/schedule'),
              LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
                .default,
            })
          : require('../activity/schedule').default,
        name: '排班',
        exact: true,
      },
      {
        component: () =>
          React.createElement(
            require('/home/ship/linein-console/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
              .default,
            { pagesPath: 'src/pages', hasRoutesInConfig: true },
          ),
      },
    ],
  },
  {
    path: '/schedule_all',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__BlankLayout" */ '../../layouts/BlankLayout'),
          LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/BlankLayout').default,
    routes: [
      {
        path: '/schedule_all/:id?',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__activity__schedule__model.js' */ '/home/ship/linein-console/src/pages/activity/schedule/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__activity__schedule__all" */ '../activity/schedule/all'),
              LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
                .default,
            })
          : require('../activity/schedule/all').default,
        name: '排班',
        exact: true,
      },
      {
        component: () =>
          React.createElement(
            require('/home/ship/linein-console/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
              .default,
            { pagesPath: 'src/pages', hasRoutesInConfig: true },
          ),
      },
    ],
  },
  {
    path: '/chatwoot',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__BlankLayout" */ '../../layouts/BlankLayout'),
          LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/BlankLayout').default,
    routes: [
      {
        path: '/chatwoot/:id?',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__activity__chatwoot__model.js' */ '/home/ship/linein-console/src/pages/activity/chatwoot/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__activity__chatwoot__" */ '../activity/chatwoot'),
              LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
                .default,
            })
          : require('../activity/chatwoot').default,
        name: '客服',
        exact: true,
      },
      {
        component: () =>
          React.createElement(
            require('/home/ship/linein-console/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
              .default,
            { pagesPath: 'src/pages', hasRoutesInConfig: true },
          ),
      },
    ],
  },
  {
    path: '/perf',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__SecurityLayout" */ '../../layouts/SecurityLayout'),
          LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/SecurityLayout').default,
    routes: [
      {
        path: '/perf/:id?',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__activity__performance__model.js' */ '/home/ship/linein-console/src/pages/activity/performance/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__activity__performance__" */ '../activity/performance'),
              LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
                .default,
            })
          : require('../activity/performance').default,
        name: '業績統計',
        exact: true,
      },
      {
        component: () =>
          React.createElement(
            require('/home/ship/linein-console/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
              .default,
            { pagesPath: 'src/pages', hasRoutesInConfig: true },
          ),
      },
    ],
  },
  {
    path: '/monitor',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__SecurityLayout" */ '../../layouts/SecurityLayout'),
          LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/SecurityLayout').default,
    routes: [
      {
        path: '/monitor/:id?',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__activity__printing__model.js' */ '/home/ship/linein-console/src/pages/activity/printing/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__activity__printing__all" */ '../activity/printing/all'),
              LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
                .default,
            })
          : require('../activity/printing/all').default,
        name: '場次監控',
        exact: true,
      },
      {
        component: () =>
          React.createElement(
            require('/home/ship/linein-console/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
              .default,
            { pagesPath: 'src/pages', hasRoutesInConfig: true },
          ),
      },
    ],
  },
  {
    path: '/print_shipping',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__BlankLayout" */ '../../layouts/BlankLayout'),
          LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/BlankLayout').default,
    routes: [
      {
        path: '/print_shipping/:id',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__activity__print_shipping__model.js' */ '/home/ship/linein-console/src/pages/activity/print_shipping/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__activity__print_shipping" */ '../activity/print_shipping'),
              LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
                .default,
            })
          : require('../activity/print_shipping').default,
        name: '列印名條',
        exact: true,
      },
      {
        component: () =>
          React.createElement(
            require('/home/ship/linein-console/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
              .default,
            { pagesPath: 'src/pages', hasRoutesInConfig: true },
          ),
      },
    ],
  },
  {
    path: '/print_qr',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__BlankLayout" */ '../../layouts/BlankLayout'),
          LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/BlankLayout').default,
    routes: [
      {
        path: '/print_qr/:id',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__activity__print_qr__model.js' */ '/home/ship/linein-console/src/pages/activity/print_qr/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__activity__print_qr" */ '../activity/print_qr'),
              LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
                .default,
            })
          : require('../activity/print_qr').default,
        name: '列印桌卡',
        exact: true,
      },
      {
        component: () =>
          React.createElement(
            require('/home/ship/linein-console/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
              .default,
            { pagesPath: 'src/pages', hasRoutesInConfig: true },
          ),
      },
    ],
  },
  {
    path: '/survey/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__BlankLayout" */ '../../layouts/BlankLayout'),
          LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/BlankLayout').default,
    routes: [
      {
        path: '/survey/:key/:admin?',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__survey__submit__model.js' */ '/home/ship/linein-console/src/pages/survey/submit/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__survey__submit" */ '../survey/submit'),
              LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
                .default,
            })
          : require('../survey/submit').default,
        name: '填寫調查',
        exact: true,
      },
      {
        component: () =>
          React.createElement(
            require('/home/ship/linein-console/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
              .default,
            { pagesPath: 'src/pages', hasRoutesInConfig: true },
          ),
      },
    ],
  },
  {
    path: '/exchange/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__BlankLayout" */ '../../layouts/BlankLayout'),
          LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/BlankLayout').default,
    routes: [
      {
        name: 'invitationlist',
        icon: 'unordered-list',
        path: '/exchange/list',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__activity__exchange__model.js' */ '/home/ship/linein-console/src/pages/activity/exchange/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__activity__exchange" */ '../activity/exchange'),
              LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
                .default,
            })
          : require('../activity/exchange').default,
        exact: true,
      },
      {
        component: () =>
          React.createElement(
            require('/home/ship/linein-console/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
              .default,
            { pagesPath: 'src/pages', hasRoutesInConfig: true },
          ),
      },
    ],
  },
  {
    path: '/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__SecurityLayout" */ '../../layouts/SecurityLayout'),
          LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/SecurityLayout').default,
    authority: ['admin', 'user'],
    routes: [
      {
        path: '/',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "layouts__BasicLayout" */ '../../layouts/BasicLayout'),
              LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
                .default,
            })
          : require('../../layouts/BasicLayout').default,
        routes: [
          {
            path: '/',
            redirect: '/activity/list',
            authority: ['admin', 'user'],
            exact: true,
          },
          {
            name: 'list',
            icon: 'unordered-list',
            path: '/activity/list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__activity__list__model.js' */ '/home/ship/linein-console/src/pages/activity/list/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__activity__list" */ '../activity/list'),
                  LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
                    .default,
                })
              : require('../activity/list').default,
            exact: true,
          },
          {
            name: 'trackinglist',
            icon: 'unordered-list',
            path: '/activity/trackinglist',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__activity__trackinglist__model.js' */ '/home/ship/linein-console/src/pages/activity/trackinglist/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__activity__trackinglist" */ '../activity/trackinglist'),
                  LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
                    .default,
                })
              : require('../activity/trackinglist').default,
            exact: true,
          },
          {
            name: 'activity.user',
            path: '/activity/user/:activity',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__activity__user__model.js' */ '/home/ship/linein-console/src/pages/activity/user/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__activity__user" */ '../activity/user'),
                  LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
                    .default,
                })
              : require('../activity/user').default,
            hideInMenu: true,
            exact: true,
          },
          {
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__404" */ '../404'),
                  LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
                    .default,
                })
              : require('../404').default,
            exact: true,
          },
          {
            component: () =>
              React.createElement(
                require('/home/ship/linein-console/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        component: () =>
          React.createElement(
            require('/home/ship/linein-console/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
              .default,
            { pagesPath: 'src/pages', hasRoutesInConfig: true },
          ),
      },
    ],
  },
  {
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () => import(/* webpackChunkName: "p__404" */ '../404'),
          LoadingComponent: require('/home/ship/linein-console/src/components/PageLoading/index')
            .default,
        })
      : require('../404').default,
    exact: true,
  },
  {
    component: () =>
      React.createElement(
        require('/home/ship/linein-console/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
          .default,
        { pagesPath: 'src/pages', hasRoutesInConfig: true },
      ),
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return (
      <RendererWrapper0>
        <Router history={history}>{renderRoutes(routes, props)}</Router>
      </RendererWrapper0>
    );
  }
}
