import request from '@/utils/request';
export async function query() {
  return request('/api/users?_t='+Math.random());
}
export async function queryCurrent() {
  return request('/api/currentUser?_t='+Math.random());
}
export async function queryNotices() {
  return request('/api/notices');
}
